@import url(https://fonts.googleapis.com/css?family=Varela+Round);
body {
  margin: 0;
  font-family: 'varela round','sans-serif';
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
    /*background-color: #00b8ff;*/
}
#content1{
    top: 130px;
    overflow-y: scroll;
    height: 100%;
}
::-webkit-scrollbar {
    display: none;
}
.content2{
    background-color: #fbfbfb;
    padding-top: 85px;
}
.topheader{
    height: 60px;;
    background-color: #ff5722;
    color: #fff;
}
#menufilterbar{
    background-color: #c7431a;
    padding: 10px;
    color: #fff;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 1030;
}
.navbar-light .navbar-brand{
    color: #fff;
}
.menu_cat_wrapper{
    display: flex;
    justify-content: space-around;
}
.menu_cat_wrapper a{
    color: #fff;
    text-decoration: none;
    padding: 0 5px;
}
.selected_menu_category{
    font-weight: bold;
    border-bottom: 3px solid #fff;
}

#cartIcon{
    width: 24px;
    height: 24px;
}
#cartIconWrapper{
    cursor: pointer;
}

.addCartBtnWrapper {
    width: 95px;
    display: flex;
    border: solid 1px #ccc;
    border-radius: 5px;
    height: 29px;
    justify-content: center;
    align-items: center;
    float: right;
}
.addCartBtnWrapper > div{
    text-align: center;
}
.addCartBtnWrapper .incr,  .addCartBtnWrapper .dcr{
    width: 30px;
    background-color: white;
    color: #2b76ab;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}
.addCartBtnWrapper .qty {
    width: 33px;
    background-color: #fff;
    font-size: 15px;
}
.cartBillContainer{
    display: flex;
    flex-direction: column;
}
.cartBillContainer > div {
    background-color: #fff;
    border: 1px solid #eee;
    width: 100%;
    padding: 12px;
}
.cartBillContainer .finalBill{
    font-weight: bold;
    font-size: 13px;
    border-top: 1px solid #000;
}
.placeOrderBlock > input{
    margin-top: 10px;
    height: 40px;
    border: 1px solid #eee;
}
.right{
    float: right;
}
.cartDetailContainer{
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid #eee;
}
.btn {
    color: #fff;
    background-color: #ff5722;
    border-color: #d8481b;
}
.btn:hover {
    background-color: #ff5722;
    border-color: #d8481b;
}
.placeorderBtn{
    font-size: 18px;
    margin-top: 10px;
}
.cartItemRow{
    border-bottom: 1px solid #eee;
}
.menu_item_card{
    padding: 10px 15px 5px 15px;
    background-color: white;
    border-bottom: .5px solid #d4d5d9;
    height:170px;
}
.menu-image {
    width: 80%;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
}
.home_category_card{
    border-radius:10px;
    align-items:"center";
    background-position:"center";
    background-size: 'cover';
    background-repeat: 'no-repeat';
    height: 200px;
}
.payment_reminder{
    color: white;
    background-color: #963915;
}
.row{
    margin-left: 0;
    margin-right: 0;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0;
    padding-left: 0;
}


